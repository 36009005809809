import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const FileAUtilityPatentApplication = () => {
    const seo = {
        metaDesc: 'File a Utility Patent Application- Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="File a Utility Patent Application- Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container">

                    <div class="">
                        <h1>1. File a Utility Patent Application</h1>
                    </div>

                    <img src="../assets/img/banner/5-e1602437609555.jpg" width="100%" alt="" />
                    <div class="">
                        <p><strong>Table of Contents</strong></p>
                        <p><strong>1.1&nbsp; <a href="#reuse" class="d-clr">Can I reuse information from my PPA or other applications?</a></strong></p>
                        <p><strong>1.2&nbsp; <a href="#drafting" class="d-clr">What should I consider in drafting the utility application?</a></strong></p>
                        <p><strong>1.3&nbsp; <a href="#writeclaims" class="d-clr">How do I write the claims?</a></strong></p>
                        <p><strong>1.4&nbsp; <a href="#filing" class="d-clr">What documents are needed in filing the utility application?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.1 <a href="#specification" class="d-clr">Specification</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.2 <a href="#ids" class="d-clr">Information Disclosure Statement (IDS)</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.3 <a href="#declaration" class="d-clr">Inventor’s Oath or Declaration</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.4 <a href="#fees" class="d-clr">Fees</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.5 <a href="#transmittalletter" class="d-clr">Transmittal letter</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.6 <a href="#drawings" class="d-clr">Drawings</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.7 <a href="#optional" class="d-clr">Optional</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.4.7.1 <a href="#entity" class="d-clr">Small entity and micro entity</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.4.7.2 <a href="#ptms" class="d-clr">Petition to Make Special (PTMS</a>)</strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.4.7.3 <a href="#assignments" class="d-clr">Assignments</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.4.7.4 <a href="#postcards" class="d-clr">Return receipt postcard</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.4.7.5 <a href="#powerofattorney" class="d-clr">Power of attorney</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.4.7.6 <a href="#npr" class="d-clr">Non-publication Request (NPR)</a></strong></p>
                        <p><strong>1.5&nbsp; <a href="#publication" class="d-clr">Can you request early publication of your application?</a></strong></p>
                        <p><strong>1.6&nbsp; <a href="#secrecy" class="d-clr">What is a secrecy order?</a></strong></p>
                    </div>

                    <div class="">
                        <h2 id="reuse">1.1 Can I reuse information from my PPA or other applications?</h2>
                        <p>&nbsp;</p>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Your utility application should be based from your PPA so you should reuse the PPA application.</li>
                                            <li>
                                                Reusing information from another patent applications is not prohibited, but you should:
                                                <ul>
                                                    <li>Cite the patent or published application in an IDS.</li>
                                                    <li>Describe in depth your improvements and benefits/advantages over the prior work.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>It is a common practice to use text from other patent applications to describe the background or state of the art, in other words, to set the stage for the invention. Additionally, if appropriate, you can modify the original texts and drawings to illustrate the operation of your invention.</p>
                        <p>To use this prior information in your utility patent application, you should cite to the original patent or published application in your application.&nbsp; You also need to submit the reference(s) in the Information Disclosure Statement (IDS Form 1449).</p>
                        <p>In particular, your specification should set forth your invention in as much details as possible because you need to distinguish your invention from the prior inventions.&nbsp; You should also explain the difference in operation or principle of the improvement whenever applicable.&nbsp; Otherwise, your application may be rejected as anticipated by or obvious over the prior art.</p>
                    </div>

                    <div class="">
                        <h2 id="drafting">1.2 What should I consider in drafting the utility application?</h2>
                        <p>Before you start the filing process, you should ask yourself whether the idea is patentable. You should also consider criteria like timing, cost, and type and subject matter of an invention. Factors include:</p>
                        <ul>
                            <li>Why is the invention needed? What problem is addressed, and what problem should the invention solve?</li>
                            <li>What major component/parts make up the invention? Can you eliminate, rearrange, or combine these components?</li>
                            <li>What components are new in the invention, and why are they needed? Are there equivalents for these components?</li>
                            <li>How are the components interconnected? Is the interconnection novel? Are there non-obvious advantages arising from your specific arrangement or interconnection?</li>
                            <li>What part(s) of the invention took the longest to develop, and why?</li>
                            <li>Are there other ways to perform the same function provided by the invention?</li>
                            <li>What problems does the invention solve, and what was the most difficult part of the problem that it solved?</li>
                            <li>What can alternative solutions be used in lieu of the invention?</li>
                            <li>What advantages does the invention have over alternative solutions, and how are these advantages achieved?</li>
                            <li>What features are desired by customers or end-users, and how can the competition provide them?</li>
                            <li>What features are desired by the competition, and how can the competition provide them?</li>
                            <li>What features are likely to be copied, and how can the competition copy them without violating your patent application (so you can describe potential design around)?.</li>
                            <li>Does the invention improve on an existing product? In what way and how do you achieve the improvements?</li>
                            <li>What other approaches might work in solving the problem?</li>
                            <li>What components or elements can you add? What can you remove?</li>
                            <li>How can you simplify or rearrange the major components or elements of the invention?</li>
                            <li>What parts can you substitute or combine?</li>
                            <li>For key elements or components, what’s the opposite of such elements? How can you use such opposite(s) in the invention?</li>
                            <li>Is the invention as simple as it can be? What can you eliminate?</li>
                            <li>Can you make it easy and inexpensive? How?</li>
                        </ul>
                    </div>

                    <div class="">
                        <h2 id="writeclaims">1.3 How do I write the claims?</h2>
                        <p>&nbsp;</p>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Patent claims define what an inventor sought to protect, it states what a patent does and does not cover, and the exclusive right awarded by the grant of a patent is defined by a patent claim.</li>
                                            <li>When writing a patent claims down, it should be clear and specific.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>PPAs do not need claims, but a utility patent application need at least one claim. The claim must point out and distinctly claim the subject matter that the inventor regards as the invention. The claim defines the scope of patent protection. Whether the USPTO grants a patent is determined largely on the language you use to identify your claim.</p>
                        <p>There are two types of claims. Claim 1 should be an independent claim that stand by itself. Typically claim 1 has a series of dependent claims that refer back to claim 1 and add additional limitations.&nbsp; Software people may think of dependent claims as instantiations of the independent claim.</p>
                        <p>Every claim has three sections—the preamble, the transitional phrase, and the body of the claim. The preamble is the first part of the claim. In the example car claim below, the preamble is the phrase “A vehicle” and the transitional phrase is “comprising” which means including (not limited by), and the body includes parts of the vehicle frame, engine, and wheels.&nbsp; Note that the body is connected together so that the engine is related to frame, and the wheels are also related to the other two elements or parts of the vehicle.</p>
                        <p>For example, independent claim 1 and dependent claims 2-3 for a car invention may be:</p>
                        <ol>
                            <li>A vehicle, comprising:</li>
                        </ol>
                        <p>A frame;</p>
                        <p>An engine mounted on the frame; and</p>
                        <p>Wheels coupled to the frame and rotated by the engine.</p>
                        <ol start="2">
                            <li>The vehicle of claim 1, wherein the engine is an electric motor.</li>
                            <li>The vehicle of claim 2, wherein the motor has bushings.</li>
                            <li>The vehicle of claim 1, wherein the engine is a gasoline engine.</li>
                        </ol>
                        <p>Graphically it can be sketched like this:</p>
                    </div>

                    <img src="../assets/img/banner/6.png" width="100%" alt="" />
                    <div class="">
                        <h2 id="filing">1.4 What documents are needed in filing the utility application?</h2>
                        <p>Your utility application should include:</p>
                        <p>&nbsp;</p>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Specification including Background, Summary of the Invention, Brief Description of the Drawings, Detailed Description, Claims, Abstract, and Drawings.
                                        <p></p>
                                        <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information Disclosure Statement&nbsp; (IDS)</p>
                                        <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Inventor’s Oath or Declaration</p>
                                        <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Filing Fees</p>
                                        <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Transmittal letter</p>
                                        <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Optional documents such as power of attorney, assignments, micro/small entity statement</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="">
                        <h3 id="specification">1.4.1&nbsp; Specification</h3>
                        <p>The specification is a collection of documents that describe the invention in details and how it is made, work or used. It must be written in full, clear, concise, and exact terms that any person knowledgeable in the same field of expertise would understand the invention. The written description should specify the claims and not include information that is not related to applicant’s invention.</p>
                        <h3>1.4.2&nbsp; Information Disclosure Statement (IDS)</h3>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>An information disclosure statement (IDS) refers to a submission of relevant background art or information about prior art to the United States Patent and Trademark Office (USPTO) by an applicant for a patent during the patent prosecution process.</li>
                                            <li>It is a duty on all patent applicants to disclose relevant art or background information that the applicant is aware of, and that may be relevant to the patentability of the applicant’s invention.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>You and your attorney must disclose everything you know about prior art that could influence the patent examiner looking at your application, or you are committing fraud.&nbsp; The IDS contains all of this information.&nbsp; It consists of a transmittal letter if you file it via USPS mail, and a form called PTO Form SB/08, which asks for you to disclose prior art.&nbsp; You must also attach copies of non-U.S. patents and articles that you find.</p>
                    </div>
                    <img src="../assets/img/banner/7.png " width="100%" alt="" />
                    <div class="">
                        <h3 id="declaration">1.4.3&nbsp; Inventor’s Oath or Declaration</h3>
                        <p>This USPTO-supplied form asks you to declare that you are the original inventor or an original</p>
                    </div>
                    <img src="../assets/img/banner/8.png" width="100%" alt="" />
                    <div class="">
                        <h3 id="fees">1.4.4&nbsp; Fees</h3>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>The cost of a patent depends upon the kind of patent, the number of tasks the patent attorney needs to perform, and the complexity of an invention. USPTO has all the rates.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Fees are dependent on a number of items, including whether you qualify for the small entity discount and if you’re filing for power of attorney. Hence, check the USPTO website for the most up-to-date information.<br /> The USPTO allows payment by personal check, money order, or credit card. For whatever payment you choose, you have to fill out a Fee Transmittal letter. For credit card payments, you also have to fill out the Credit Card Payment Form (Form 2038).</p>
                        <h3 id="trasmittalletter">1.4.5&nbsp; Transmittal letter</h3>
                        <p>A transmittal letter is similar to the PPA Cover Sheet. It is a checklist for application elements such as the fee transmittal form, if you claim small entity or micro entity for fee reduction, the specification including the background, summary</p>
                    </div>
                    <img src="../assets/img/banner/9.png" width="100%" alt="" />
                    <div class="">
                        <h3 id="drawings">1.4.6&nbsp; Drawings</h3>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Drawings are part of the specification and can be a very powerful tool to illustrate the operation of the invention</li>
                                            <li>We recommend an overabundance is use of drawings</li>
                                            <li>While drawings don’t have to be works of art, but they should describe and showcase the invention with a great deal of accuracy, and they must follow the drawing rules by the USPTO.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Be aware that once you filed, your drawings cannot be changed except to correct minor typographical errors, and anything submitted after the filing date of the application may not be used to overcome any insufficiency of the specification due to lack of an enabling disclosure or otherwise inadequate disclosure therein, or to supplement the original disclosure.&nbsp; Further, since “one picture is worth a thousand words”, we recommend providing more drawings than the minimum since drawings can be a powerful tool to explain how your invention operates.</p>
                        <p>With a utility patent application, the USPTO requires formal drawings. These are typically done by professionals because the drawings need adhere to specific USPTO requirements, such as:</p>
                        <ul>
                            <li>Drawings should include everything that’s mentioned in the claims.</li>
                            <li>Drawings should usually be in black and white. Sometimes they are in color but only in certain circumstances and only if necessary. Photos are not ordinarily accepted for utility and design patent applications unless that’s the only practical way of displaying information.</li>
                            <li>Drawings should include the inventor’s name, application or docket number, and the invention’s title. All of this information must be on the front of each sheet at the top margin. Each sheet must also be labeled as “Replacement Sheet,” “New Sheet,” or “Annotated Sheet.”</li>
                            <li>Tables, waveforms, and chemical and mathematical formulas can be treated as drawings, and if so they must comply with the aforementioned guidelines. Furthermore, labels should accompany each formula because they are treated as separate drawings.</li>
                            <li>For waveform groups, those are considered a single figure with a common vertical axis and time noted on the horizontal axis. A different letter, noted near the vertical axis, must be assigned to each individual waveform that the specification identifies.</li>
                            <li>Drawings can contain various viewpoints and symbols to identify particular aspects noted in the specification. A legend and labels are especially helpful in such instances. Some accepted symbols and legends are noted in the “Guide for the Preparation of Patent Drawings,” which is on www.uspto.gov.</li>
                            <li>Drawings or photographs should be neat, accurate and to scale.</li>
                            <li>Each component shown in the drawing or photograph should be labeled with a number.</li>
                        </ul>
                        <p>Here is a sample drawing from US8, 000,000:</p>
                    </div>
                    <img src="../assets/img/banner/10-727x1024.png" width="50%" alt="" />
                    <img src="../assets/img/banner/11.png" width="50%" alt="" />
                    <div class="">
                        <h3 id="optional">1.4.7&nbsp; Optional</h3>
                        <h4 id="entity">10.4.7.1&nbsp; Small entity and micro entity</h4>
                        <p>Small entity<br /> You may qualify for a small entity discount if you are an independent inventor, small business owner, or nonprofit. The fees for filing, searching, examining, issuing, appealing, and maintaining patent applications and patents are reduced by 50 percent for any small entity that qualifies for reduced fees, and are reduced by 75 percent for any micro entity that files a certification that the requirements for micro-entity are met.</p>
                        <p>Small entity status may be appropriate if the inventors have not assigned any rights in the invention set forth in the application and are not under any obligation to do so (as may be required in an employment contract) to a large entity. A small entity can be an independent inventor with all rights to the invention, or the inventor(s) have transferred all rights to a small business generally defined as those with less than 500 employees. If you qualify as a small entity for patent fee purposes, no special form is required to claim your entitlement to reduced fees (you may check a special box on the transmittal form).<br /> Claiming small entity status is simple: the act of paying the small entity rates after ensuring that you qualify for the small entity status is sufficient for the patent office to grant you the small entity status.<br /> You can also request the small entity status by checking the small entity box on the utility application transmittal letter, or check the box in the application information box of the ADS:</p>
                    </div>
                    <img src="../assets/img/banner/12.png" width="100%" alt="" />
                    <div class="">
                        <p><a name="_Toc51802904"></a>Micro-entity</p>
                        <p>You may be entitled to a fee reduction of 75% from most PTO fees if you submit the Certification of Micro Entity Status. This fee reduction is applicable where the applicant is:</p>
                        <ul>
                            <li>&nbsp;An individual,</li>
                            <li>A company having not more than 500 employees, or</li>
                            <li>A non-profit organization (including a university).</li>
                        </ul>
                        <p>To qualify, you must certify that you meet the conditions, and the easiest is to fill out the PTO form such as the below certification:</p>
                    </div>
                    <img src="../assets/img/banner/13.png" width="100%" alt="" />
                    <div class="">
                        <h4 id="ptms">1.4.7.2&nbsp; Petition to Make Special (PTMS)</h4>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>You can accelerate your utility patent application based on age, health, and what industry your invention helps, such as terrorism, energy.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>If you qualify, you can accelerate your utility patent application out of turn with the Accelerated Examination Program (AEP).&nbsp; There is a range of categories that may make you eligible for AEP.&nbsp; For example, you may qualify based on your age; health; or special considerations such as inventions addressing COVID, for example.</p>
                        <p>To see if you qualify, you must file your utility patent application online and submit the form at the same time.&nbsp; You must follow other detailed instructions which can be quite rigorous, so visit the USPTO site for additional information to request the examination acceleration.</p>
                    </div>
                    <img src="../assets/img/banner/14.png" width="100%" alt="" />
                    <div class="">
                        <h4 id="assignments">1.4.7.3&nbsp; Assignments</h4>
                        <table class="table table-bordered mt-3">
                            <tbody class="table table-bordered mt-3">
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Ownership of the patent can be transferred to another via assignment, which should be recorded with the USPTO.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p><strong><em>&nbsp;</em></strong>Ownership of these items can be transferred to another via an assignment.&nbsp; As earlier noted, assignments should be recorded with the USPTO.&nbsp; This can be done when you file the patent application or afterward.</p>
                        <p>Here is a sample assignment form for a public company as listed by the SEC at
                            <a href="https://www.sec.gov/Archives/edgar/data/913277/000093176302003259/dex24.htm" class="d-clr" target="_blank">FORM OF PATENT ASSIGNMENT</a></p>
                    </div>

                    <div class="">
                        <p style={{ textAlign: 'center' }}><strong><u>EXHIBIT C</u></strong></p>
                        <p style={{ textAlign: 'center' }}><strong>PATENT ASSIGNMENT </strong></p>
                        <p>WHEREAS, Clarus Corporation, a Delaware corporation, with an address at <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u> (“Assignor”), or Redeo Technologies, Inc., a Delaware corporation, owns all right, title and interest in and to the patents and/or patent applications identified in <u>Exhibit A</u> attached hereto, including the inventions described therein and the patents issued and reissued thereon (collectively, the “<u>Patents</u>”), the renewals therefor and all claims for past or future infringement thereof.</p>
                        <p>WHEREAS, Epicor Software Corporation, a Delaware corporation with an address at <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u> (“Assignee”), and Assignor have entered into an Asset Purchase Agreement (the “Agreement”) dated October <u>&nbsp;&nbsp;&nbsp;&nbsp;</u>, 2002, under which Assignor agreed to sell and Assignee agreed to purchase certain assets of Assignor, including the aforesaid Patents, and the applications and renewals therefor and all claims for past or future infringement thereof.</p>
                        <p>NOW THEREFORE, for valuable consideration, the receipt and sufficiency of which is hereby acknowledged, Assignor does hereby sell, assign, convey and transfer unto Assignee, its successors and assigns, free and clear of any and all liens, restrictions, claims and encumbrances, Assignor’s entire right, title, and interest in and to the Patents and divisions, continuations or continuations-in-part thereof, together with all rights of registration, maintenance, and protection thereof in any form, all rights to income, royalties, damages and payments now due or hereafter due or payable in respect thereto, and all rights of recovery and of legal action for past or future infringements and of interference proceedings and reexaminations involving such Patents.</p>
                        <p>This Assignment is deemed to be executed and delivered within the State of Georgia, and it is the intention of the parties that it shall be construed, interpreted and applied in accordance with the laws of the State of Georgia without regard to its conflicts of law principles.</p>
                        <p>&nbsp;</p>
                        <p style={{ textAlign: 'center' }}><strong>[SIGNATURES ON FOLLOWING PAGE] </strong></p>
                        <p>&nbsp;</p>
                        <p style={{ textAlign: 'center' }}>(SIGNATURE PAGE TO PATENT ASSIGNMENT)</p>
                        <p>&nbsp;</p>
                        <p>IN WITNESS WHEREOF, Assignor has duly executed this Assignment on this <u>&nbsp;&nbsp;&nbsp;&nbsp;</u> day of October, 2002.</p>
                        <table class="table table-bordered mt-3 alignright" width="40%">
                            <tbody>
                                <tr>
                                    <td colspan="3" width="37%">
                                        <p style={{ textAlign: 'right' }}>CLARUS CORPORATION</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td width="2%">By:</td>
                                    <td width="3%"></td>
                                    <td width="37%"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" width="37%">
                                        Name:
                                        <p></p>
                                        <p>Title:</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>&nbsp;</p>
                        <table class="table table-bordered mt-3 alignright" width="40%">
                            <tbody>
                                <tr>
                                    <td colspan="3" width="37%">‘REDEO TECHNOLOGIES, INC.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td width="2%">By:</td>
                                    <td width="3%"></td>
                                    <td width="37%"></td>
                                </tr>
                                <tr>
                                    <td width="2%">
                                        Name:
                                        <p></p>
                                        <p>Title:</p>
                                    </td>
                                    <td width="3%"></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>

                        <p>State of &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                        <p>) ss.:</p>
                        <p>County of &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                        <p>On this <u>&nbsp;&nbsp;&nbsp;&nbsp;</u> day of <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>, 2002, before me, <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>, personally appeared <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>, personally known to me (or proved to me on the basis of satisfactory evidence) to be the person whose name is subscribed to the within instrument and acknowledged to me that he executed the same in his authorized capacity and that by his signature on the instrument the person, or the entity upon behalf of which the person acted, executed the instrument.</p>
                        <p>IN WITNESS WHEREOF, I have hereunto set my hand and official seal.</p>
                        <p>Notary Public <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></p>
                        <p>My Commission expires: <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;</u></p>
                        <p>Notarial Seal</p>
                        <p style={{ textAlign: 'center' }}><strong>EXHIBIT A </strong></p>
                        <p style={{ textAlign: 'center' }}><strong><u>PATENTS</u></strong></p>
                        <div class="table-responsive">
                            <table class="table table-bordered mt-3" width="100%">
                                <tbody>
                                    <tr>
                                        <td width="11%"><strong>Filed</strong></td>
                                        <td width="3%"></td>
                                        <td width="11%"><strong>Application Number</strong></td>
                                        <td width="3%"></td>
                                        <td width="11%"><strong>Country</strong></td>
                                        <td width="3%"></td>
                                        <td>
                                            <strong>Patent No. (P)</strong>
                                            <p></p>
                                            <p><strong>or Serial No. (S)</strong></p>
                                        </td>
                                        <td width="3%"></td>
                                        <td><strong>Date</strong></td>
                                        <td width="3%"></td>
                                        <td><strong>Name of Inventor</strong></td>
                                        <td width="3%"></td>
                                        <td><strong>Title of Invention</strong></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p style={{ textAlign: 'center' }}><strong><u>PATENT APPLICATIONS</u></strong></p>
                        <div class="table-responsive">
                            <table class="table table-bordered mt-3" width="100%">
                                <tbody>
                                    <tr>
                                        <td width="11%"><strong>Filed</strong></td>
                                        <td width="3%"></td>
                                        <td width="11%"><strong>Application Number</strong></td>
                                        <td width="3%"></td>
                                        <td width="11%"><strong>Country</strong></td>
                                        <td width="3%"></td>
                                        <td>
                                            <strong>Patent No. (P)</strong>
                                            <p></p>
                                            <p><strong>or Serial No. (S)</strong></p>
                                        </td>
                                        <td width="3%"></td>
                                        <td><strong>Date</strong></td>
                                        <td width="3%"></td>
                                        <td><strong>Name of Inventor</strong></td>
                                        <td width="3%"></td>
                                        <td><strong>Title of Invention</strong></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td width="3%"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>&nbsp;</p>
                        <p>Recordation of the Assignment</p>
                        <p>Once you have a signed copy of the assignment, you can head over to epas.uspto.gov to record your assignment on line.</p>
                    </div>
                    <img src="../assets/img/banner/16.png" width="100%" alt="" />
                    <div class="">
                        <p>When you click on the EPAS link at the bottom, a guideline page is shown:</p>
                    </div>
                    <img src="../assets/img/banner/17.png" width="100%" alt="" />
                    <div class="">
                        <p>When you click on the “Start” button, you will see a conveyance type page that specifies the Nature of Conveyance. You may select one of the pre-formatted nature of conveyance types or you may select the conveyance type “Other” and enter text that describes the interest conveyed or transaction to be recorded. If the nature of conveyance is an “Assignment”, “Merger”, “Change of Name”, or “Nunc Pro Tunc” ,do not use this box but instead check the appropriate conveyance box above.</p>
                        <p>An assignment is a transfer of ownership of an IP asset such as a patent or trademark application, published application, issued patent or trademark registration from one entity to another.</p>
                        <p>A Merger is a union of two or more commercial interests. This is a complete transfer of ownership from all the identified companies (conveying parties) to a newly formed business entity (receiving party). Usually, two or more parties (the conveying parties) merge their assets and form a new company (receiving party). Often, the merged company has a new name but, sometimes, the name will be the name of one of the merged companies.</p>
                        <p>A Merger and Change of Name is a union of two or more commercial interests. This is a complete transfer of ownership from all the identified companies (conveying parties) to a newly formed business entity (newly merged company). Two or more parties (the conveying parties) merge their assets and form a new company (merged company). The merged company then changes its name and becomes the receiving party.</p>
                        <p>Change of Name is a conversion from one name to another.</p>
                        <p>Court Order is for a Court ordered transfer of, or change in, ownership.</p>
                        <p>Corrective Assignments is for a request to re-record a previously recorded assignment with an error made in either the coversheet data or the supporting legal documentation (of the original submission) by the customer. &nbsp;The coversheet and assignment used for the original recordation must be attached to any request for a corrective assignment.</p>
                    </div>
                    <img src="../assets/img/banner/18.png" width="100%" alt="" />
                    <div class="">
                        <p>Next you enter your correspondence information:</p>
                    </div>
                    <img src="../assets/img/banner/19.png" width="100%" alt="" />
                    <div class="">
                        <p>And then you type in the name of the people conveying (assigning rights) to the new owner:</p>
                    </div>
                    <img src="../assets/img/banner/20.png" width="100%" alt="" />
                    <div class="">
                        <p>And then the receiving party</p>
                    </div>
                    <img src="../assets/img/banner/21.png" width="100%" alt="" />
                    <img src="../assets/img/banner/23.png" width="100%" alt="" />
                    <div class="">
                        <p>And finally the patent list</p>
                    </div>
                    <img src="../assets/img/banner/23 (1).png" width="100%" alt="" />
                    <div class="">
                        <p>Next, you digitally sign the coversheet, and the system shows a validation screen for you to confirm the accuracy of the data. Check the data carefully. If you find any errors, go back to the appropriate screen and correct. Otherwise, select the Submit button to proceed.</p>
                    </div>
                    <img src="../assets/img/banner/24.png" width="100%" alt="" />
                    <div class="">
                        <h4 id="postcards">1.4.7.4 Return receipt postcard</h4>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Response from USPTO tend to take long, to make it easier enclose stamped postcard with your application with as much information.</li>
                                            <li>Within a month they will stamp the postcard with 8-digit number and date of receipt.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>While electronic filing is encouraged (and mailed filings cost more now), if you elect to file by mail, it can take a while for the USPTO to respond to your application. To get an early informal acknowledgement from the PTO, you shoud enclose a stamped postcard with your application so they can easily acknowledge receipt. It still takes about up to a month for you to receive this postcard.<br /> On the postcard, try to include as much information as possible, such as the inventor’s name, invention’s title, claims, abstract, date you signed the oath/declaration, check number and amount. You are responsible for placing proper postage on the self-addressed postcard with stamp(s) in the correct amount or a meter stamp postmark that complies with USPS requirements.<br /> When the USPTO receives this, it will stamp the postcard with the application number and date of receipt. You should keep both of these confidential.</p>
                        <h4 id="powerofattorney">1.4.7.5 Power of attorney</h4>
                        <p>If you have an attorney, you usually grant the power of attorney to your lawyer. As earlier noted, by granting a power of attorney, the person with this power can represent or handle a patent application on your behalf. This means the person with the power of attorney has the authority to communicate with the patent office. This grant of authority must be filed with the USPTO.</p>
                    </div>
                    <img src="../assets/img/banner/26.png" width="100%" alt="" />
                    <div class="">
                        <h4 id="npr">1.4.7.6 Non-publication Request (NPR)</h4>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>A non-publication request (NPR) is a request by the patent applicant to not publish a non-provisional patent application.</li>
                                            <li>By default, after every 18 months of non-provisional patent applications are published. And the non-publication request halts the publication.</li>
                                            <li>USPTO can publish applications early, at applicants’ request. This can enable applicants to obtain reasonable royalties from others who make, use, or sell the invention between when the application is published and when the patent is granted.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Provisional applications are never published, but utility applications are published 18 months after filing.&nbsp; If you don’t want to be published, at the time of filing, you have to submit a Non-Publication Request (NPR; Form SB/35).&nbsp; By submitting this request, you’re declaring that you will not file internationally for a non-U.S. patent as the international patent offices commonly publish your applications at the 18 month period.&nbsp; If you change your mind, deciding to file for a non-U.S. patent, you must alert the USPTO within 45 days of an international filing and allow publication of your U.S. utility patent application.&nbsp; You can use the non-publication request form:</p>
                    </div>
                    <img src="../assets/img/banner/27.png" width="100%" alt="" />
                    <div class="">
                        <h5>Can you abandon your application to keep its contents secret?</h5>
                        <p>Yes, it is quite simple. You only need to file the petition to expressly abandon your application as follows:</p>
                    </div>
                    <img src="../assets/img/banner/28.png" width="100%" alt="" />
                    <div class="">
                        <h2 id="publication">1.5 Can you request early publication of your application?</h2>
                        <p>All applications are held in the strictest confidence by the USPTO until it issues a patent or publishes an application 18 months from filing. For early publication, there’s a fee required.<br /> After the USPTO publishes an application, the public can request a copy of it. And after it grants a patent, the application and all correspondence leading up to the patent grant are available to the public.<br /> So why request early publication if you have to pay the fee and let others know about your application in advance? In addition to having prior art status citations, one benefit is that you may seek past damages for infringement that occurred prior to issuance of the patent and after the publication of the patent application, if a patent ultimately issues with claims “substantially identical to the claims in the published application,” and the patent owner gave actual notice of publication to an alleged infringer. In that case, the patent owner may collect a reasonable royalty for the period beginning with the date of publication of the application and ending with the date of the patent grant. However, claims are often amended during examination, so this is a difficult condition to meet. One case where this can occur is if you claim very specifically to your preferred embodiment to catch someone copying your product.</p>
                    </div>

                    <div class="">
                        <h2 id="secrecy">1.6 What is a secrecy order?</h2>
                        <p>The Invention Secrecy Act of 1951 requires the government to impose “secrecy orders” on certain patent applications that may be detrimental to national security. Thus, when notified by the chief officer of a defense agency that publication or disclosure of the invention by the granting of a patent would be detrimental to the national security, an order that the invention be kept secret will be issued by the Commissioner for Patents.<br /> This, consequently, restricts disclosure of the invention and enables the USPTO to withhold the grant of a patent. This requirement can be imposed even when the application arises from work done without government sponsorship or support.<br /> If you disagree, you can submit a petition for rescission or removal of a secrecy order by reciting facts that purport to render the order ineffectual or futile if this is the basis of the petition. When prior publications or patents are alleged the petition must give complete data as to such publications or patents and should be accompanied by copies. The petition must identify any contract between you and the Government under which the invention was developed. You should state if there is no such contract.</p>
                    </div>
                </div>

            </section>

        </Layout>
    )
};

export default FileAUtilityPatentApplication;